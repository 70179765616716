<template>
  <div class="container">
    <header>
      <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
      <div class="h_info">
        <div class="h_txt">${{ collectionInfo.PayAmount }}</div>
        <div class="h_txt">{{ $t('pay').ToolUsage.toUpperCase() }}</div>
      </div>
    </header>
    <!-- 页签 -->
    <div class="navTab">
      <div
        class="item"
        @click="activeNav = 'Bank'"
        :class="{ activeNav: activeNav == 'Bank' }"
      >
        {{ $t('pay').Bank.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="activeNav = 'TRC20'"
        :class="{ activeNav: activeNav == 'TRC20' }"
      >
        {{ $t('pay').TRC20.toUpperCase() }}
      </div>
      <div
        class="item"
        @click="activeNav = 'QRCode'"
        :class="{ activeNav: activeNav == 'QRCode' }"
      >
        {{ $t('pay').QRCode.toUpperCase() }}
      </div>
    </div>
    <!-- 银行卡 -->
    <div v-if="activeNav == 'Bank'">
      <div class="up">
        <div class="up_item">
          <div class="up_l">
            <div class="up_label">{{ $t('pay').Name.toUpperCase() }}</div>
            <div class="up_txt">{{ systemBankInfo.Name }}</div>
          </div>
          <img
            src="@/assets/newImg/donate_p_copy.png"
            width="14px"
            @click="onCopy(systemBankInfo.Name)"
          />
        </div>
        <div class="up_item">
          <div class="up_l">
            <div class="up_label">{{ $t('pay').BankName.toUpperCase() }}</div>
            <div class="up_txt">{{ systemBankInfo.ReceivingBank }}</div>
          </div>
          <img
            src="@/assets/newImg/donate_p_copy.png"
            width="14px"
            @click="onCopy(systemBankInfo.ReceivingBank)"
          />
        </div>
        <div class="up_item">
          <div class="up_l">
            <div class="up_label">
              {{ $t('pay').BankAccount.toUpperCase() }}
            </div>
            <div class="up_txt">{{ systemBankInfo.AccountNumber }}</div>
          </div>
          <img
            src="@/assets/newImg/donate_p_copy.png"
            width="14px"
            @click="onCopy(systemBankInfo.AccountNumber)"
          />
        </div>
      </div>
    </div>
    <!-- 渠道 -->
    <div class="up" v-if="activeNav == 'TRC20'">
      <div class="up_item">
        <div class="up_l">
          <div class="up_label">{{ $t('pay').Channel.toUpperCase() }}</div>
          <div class="up_txt">TRC20</div>
        </div>
      </div>
      <div class="up_item">
        <div class="up_l">
          <div class="up_label">{{ $t('pay').Address.toUpperCase() }}</div>
          <div class="up_txt">{{ systemBankInfo.TRC20 }}</div>
        </div>
        <img
          src="@/assets/newImg/donate_p_copy.png"
          width="14px"
          @click="onCopy(systemBankInfo.TRC20)"
        />
      </div>
    </div>
    <!-- 二维码 -->
    <div class="imgcode" v-if="activeNav == 'QRCode'">
      <div class="select">
        <div class="im_lable">{{ $t('pay').SelectQRCode.toUpperCase() }}</div>
        <van-dropdown-menu active-color="#492fb6" ref="checkbox">
          <van-dropdown-item
            v-model="selectQrCodeIndex"
            :options="systemQrCodeList"
          />
        </van-dropdown-menu>
        <img src="@/assets/newImg/donate_p_select.png" width="12px" />
      </div>
      <div
        class="im_img"
        @click="
          toImagePreview(systemQrCodeList[selectQrCodeIndex].CollectionQRCode)
        "
      >
        <img
          :src="systemQrCodeList[selectQrCodeIndex].CollectionQRCode"
          width="150px"
        />
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="ba_input">
        <van-field
          v-model="Remark"
          :label="$t('pay').LeaveWord.toUpperCase()"
          :placeholder="$t('common').input"
        ></van-field>
      </div>
      <div class="ba_img">
        <div class="ba_txt">{{ $t('pay').UpDocument.toUpperCase() }}</div>
        <van-uploader preview-size="100" :after-read="justAfterRead">
          <img v-if="PayImg" :src="PayImg" alt="" />
        </van-uploader>
      </div>
      <div class="ba_msg">{{ $t('pay').Hubmsg }}</div>
      <div class="ba_btn" @click="handleSub">
        {{ $t('pay').SubmitApplication.toUpperCase() }}
      </div>
    </div>
    <!-- 等待平台审核弹窗 -->
    <van-dialog v-model="showPlatformAudit" :showConfirmButton="false">
      <div class="dialog_upLock">
        <div class="di_info">
          <div class="di_i_title">
            {{ $t('pay').SubmissionSuccessful.toUpperCase() }}
          </div>
          <div class="di_i_msg">{{ $t('pay').PendingReview }}</div>
          <div class="di_i_txt">{{ $t('pay').UpconfirmMsg }}</div>
          <div class="di_i_btn" @click="$router.push({ name: 'Home' })">
            {{ $t('pay').ReturnHome }}
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { ImagePreview } from 'vant'

export default {
  data() {
    return {
      showPlatformAudit: false,
      activeNav: 'Bank',
      collectionInfo: {},
      systemBankInfo: {},
      systemQrCodeList: [],
      selectQrCodeIndex: 0,
      Remark: '',
      PayImg: '',
      isSubmit: false,
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getCollection()
    this.getSystemBank()
    this.getSystemQrCode()
  },
  methods: {
    //复制方法
    onCopy(val) {
      navigator.clipboard.writeText(val).then(() => {
        this.$toast(this.$t('common').CopySuccess)
      })
    },
    //图片预览
    toImagePreview(val) {
      ImagePreview({images:[val],closeable: true})
    },
    //获取收款信息
    async getCollection() {
      const form = {
        TransferType: 'ToolFee'
      }
      const aBase = await userApi.getCollection(form)
      this.collectionInfo = aBase.Data
    },
    // 获取系统银行卡
    async getSystemBank() {
      const res = await userApi.getSystemBank()
      this.systemBankInfo = res.Data
    },
    // 获取系统收款二维码
    async getSystemQrCode() {
      const res = await userApi.getSystemQrCode()
      this.systemQrCodeList = res.Data
      this.systemQrCodeList.forEach((item, index) => {
        item.text = item.Name
        item.value = index
      })
    },
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.PayImg = await userApi.getCaptchaFile(formdata)
    },
    //提交支付订单
    async handleSub() {
      if (!this.PayImg) return
      if (this.isSubmit) return
      const form = {
        TransferType: 'ToolFee',
        PayType: this.activeNav,
        PayImg: this.PayImg,
        Remark: this.Remark
      }
      if (this.activeNav == 'QRCode') {
        //二维码必选
        if (!this.systemQrCodeList[this.selectQrCodeIndex].QRCodeId) return
        form.QRCodeId = this.systemQrCodeList[this.selectQrCodeIndex].QRCodeId
      }
      this.isSubmit = true
      await userApi.addPayOrder(form)
      this.isSubmit = false
      this.showPlatformAudit = true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background: #f0f2f5;
  padding-bottom: 20px;
  header {
    color: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    height: 161px;
    background-image: url(../../assets/newImg/donate_p_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 20px 0 20px;
    .h_info {
      margin-top: 50px;
      text-align: center;
      .h_txt {
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 14px;
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 20px;
    background: #fff;
    border-radius: 12px;
    height: 45px;
    padding: 0 15px;
    .t_txt {
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 12px;
      color: #492fb6;
    }
    .t_star {
      width: 39px;
      height: 17px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      border-radius: 5px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 8px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .up {
    margin: 15px 20px;
    background: #fff;
    border-radius: 12px;
    padding: 5px 15px;
    .up_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 12px;
      color: #492fb6;
      padding: 10px 0;
      .up_txt {
        color: #333333;
        margin-top: 5px;
      }
    }
  }
  .navTab {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 0 20px;
    height: 45px;
    background-image: url(../../assets/newImg/donate_p_tab.png);
    background-size: 100% 100%;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33%;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 9px;
      color: #ffffff;
      margin: 6px 8px;
    }
    .activeNav {
      color: #9a5300;
      background-image: url(../../assets/newImg/active_btn.png);
      background-size: 100% 100%;
    }
  }
  .select {
    margin: 15px 20px;
    background: #fff;
    border-radius: 12px;
    padding: 0 15px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFang SC;
    font-weight: 800;
    font-size: 12px;
    color: #492fb6;
    .im_txt {
      color: #333333;
    }
    ::v-deep .van-dropdown-menu {
      width: 35%;
    }
    ::v-deep .van-dropdown-menu__bar {
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0);
      height: 45px;
    }
    ::v-deep .van-dropdown-menu__title {
      color: #333333;
      font-weight: 800;
      font-size: 12px;
    }
    ::v-deep .van-dropdown-menu__title::after {
      opacity: 0;
    }
  }
  .imgcode {
    .im_img {
      margin: 15px 20px;
      background: #fff;
      border-radius: 12px;
      padding: 15px;
      text-align: center;
    }
  }
  .footer {
    font-family: PingFang SC;
    font-weight: 800;
    font-size: 12px;
    .ba_input {
      margin: 0 20px;
      height: 45px;
    }
    ::v-deep .van-field__label {
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 12px;
      color: #492fb6;
      width: 90px;
    }
    ::v-deep .van-cell {
      background: #fff;
      border-radius: 12px;
      border: none;
      padding: 0 15px;
      height: 45px;
      display: flex;
      align-items: center;
    }
    .ba_img {
      margin: 15px 20px;
      background: #fff;
      border-radius: 12px;
      padding: 15px;
      .ba_txt {
        font-family: PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #492fb6;
        margin-bottom: 15px;
      }
      ::v-deep .van-uploader__upload-icon {
        color: #492fb6;
        font-size: 24px;
      }
    }
    .ba_msg {
      margin: 0 20px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #999999;
    }
    .ba_btn {
      margin: 20px;
      height: 44px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      box-shadow: 0px 2px 4px 0px rgba(173, 61, 0, 0.75);
      border-radius: 22px;
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 13px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
::v-deep .van-dialog {
  background-color: transparent;
  width: 320px;
}
.dialog_upLock {
  background-image: url(../../assets/newImg/dialog_warn.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .di_info {
    width: 100%;
    height: 373px;
    padding-top: 143px;
    text-align: center;
    .di_i_title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 18px;
      color: #ef001e;
    }
    .di_i_msg {
      margin: 15px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 15px;
      color: #333333;
    }
    .di_i_txt {
      margin: 15px;
      padding: 10px;
      height: 100px;
      background: #ffffff;
      border-radius: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #333333;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: left;
    }
    .di_i_btn {
      margin: 15px;
      height: 39px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
